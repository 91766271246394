import React, { createContext, useState, useContext } from 'react';

const LocalStateContext = createContext();
const LocalStateProvider = LocalStateContext.Provider;

function DocumentsProvider({ children }) {
  const [pendingBookings, setPendingBookings] = useState([])
  const [pendingBookingDetails, setPendingBookingDetails] = useState([])

  const [referredBookings, setReferredBookings] = useState([])
  const [referredBookingDetails, setReferredBookingDetails] = useState([])

  const [classifiedBookings, setClassifiedBookings] = useState([])
  const [classifiedBookingDetails, setClassifiedBookingDetails] = useState([])
  
  const [deniedBookings, setDeniedBookings] = useState([])
  
  const [getPendingBookingsSorter, setHandleGetPendingBookingsSorter] = useState(() => () => [])
  const [getPendingBookingsSupervisor, setHandleGetPendingBookingsSupervisor] = useState(() => () => [])
  const [getClassifiedBookings, setHandleGetClassifiedBookings] = useState(() => () => [])
  const [getReferredBookings, setHandleGetReferredBookings] = useState(() => () => [])
  const [getDeniedBookings, setHandleGetDeniedBookings] = useState(() => () => [])
  const [getBookings, setGetBookings] = useState(() => () => [])
  const [openError, setOpenError] = useState(false)

  return (

    <LocalStateProvider
      value={{
        pendingBookings, setPendingBookings,
        pendingBookingDetails, setPendingBookingDetails,
        referredBookings, setReferredBookings,
        referredBookingDetails, setReferredBookingDetails,
        classifiedBookings, setClassifiedBookings,
        deniedBookings, setDeniedBookings,
        classifiedBookingDetails, setClassifiedBookingDetails,
        getPendingBookingsSorter, setHandleGetPendingBookingsSorter,
        getPendingBookingsSupervisor, setHandleGetPendingBookingsSupervisor,
        getClassifiedBookings, setHandleGetClassifiedBookings,
        getReferredBookings, setHandleGetReferredBookings,
        getDeniedBookings, setHandleGetDeniedBookings,
        getBookings, setGetBookings,
        openError, setOpenError
      }}
    >
      {children}
    </LocalStateProvider>
  );
}

function useDocuments() {
  const all = useContext(LocalStateContext);
  return all;
}

export { DocumentsProvider, useDocuments };
