import { lazy } from 'react';

import FaceIcon from '@material-ui/icons/Face';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ExitToApp from '@material-ui/icons/ExitToApp';
import WorkIcon from '@material-ui/icons/Work';
import PersonAdd from '@material-ui/icons/PersonAdd';
import Money from '@material-ui/icons/Money';
import Payment from '@material-ui/icons/Payment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AccountBox from '@material-ui/icons/AccountBox';
import BookIcon from '@material-ui/icons/Book';
import menuBook from '@material-ui/icons/MenuBook';
import Assignment from '@material-ui/icons/Assignment';

const SignOutFirebase = lazy(() => import('screens/SignOutFirebase'));
const EsqPay = lazy(() => import('EsqPay'));
const Account = lazy(() => import('screens/Account'));

const Members = lazy(() => import('screens/members'));
const AdminApproval = lazy(() => import('screens/admin'));
const Roles = lazy(() => import('screens/Role'));
const DocTypes = lazy(() => import('screens/DocTypes'));
const NotaryStatements = lazy(() => import('screens/NotaryStatements'));
// const NotaryApprovedStatements = lazy(() => import("screens/NotaryApprovedStatements"))
// const NotaryPaidStatements = lazy(() => import("screens/NotaryPaidStatements"))
const BookingsPending = lazy(() => import('screens/BookingsPending'));
const BookingsCancelled = lazy(() => import('screens/BookingsCancelled'));
const BookingsDenied = lazy(() => import('screens/BookingsDenied'));
const BookingsNotarized = lazy(() => import('screens/BookingsNotarized'));

const PaymentReconUpload = lazy(() => import('screens/PaymentReconUpload'));
const DepositReconUnmatched = lazy(() =>
	import('screens/DepositReconUnmatched')
);
const DepositReconMatched = lazy(() => import('screens/DepositReconMatched'));
const DepositPaymentUnmatched = lazy(() =>
	import('screens/DepositPaymentUnmatched')
);
const DepositReconUpload = lazy(() => import('screens/DepositReconUpload'));
const PaymentReconUnmatched = lazy(() =>
	import('screens/PaymentReconUnmatched')
);
const PaymentReconALL = lazy(() => import('screens/PaymentRecon'));
const PaymentReconNOT = lazy(() => import('screens/PaymentReconNOT'));
const PaymentReconPAID = lazy(() => import('screens/PaymentReconPAID'));
const PaymentReconCANCELLED = lazy(() =>
	import('screens/PaymentReconCANCELLED')
);
const PaymentReconDENIED = lazy(() => import('screens/PaymentReconDENIED'));
// const Payout = lazy(() => import("screens/Payout"))
const FeesRates = lazy(() => import('screens/FeesRates'));
const FeesRatesAdmin = lazy(() => import('screens/FeesRatesAdmin'));
const DocumentsSorter = lazy(() => import('screens/DocumentsSorter'));
const DocumentsSupervisor = lazy(() => import('screens/DocumentsSupervisor'));
const SignUpWeb = lazy(() => import('screens/SignUpWeb'));
const SignUpWebAdmin = lazy(() => import('screens/SignUpWeb user'));
const ExportPayments = lazy(() => import('screens/ExportPayments'));
const DocumentClass = lazy(() => import('screens/DocumentClass'));
const DocumentClassNotary = lazy(() => import('screens/DocumentClassNotary'));
const FeesRatesNew = lazy(() => import('screens/FeesRatesNew'));
const ProcessedRefunds = lazy(() => import('screens/Refund/ProcessedRefunds'));
const RefundAttempts = lazy(() => import('screens/Refund/RefundAttempts'));
const RefundPayments = lazy(() => import('screens/Refund/RefundPayments'));
const ReconcileRefunds = lazy(() => import('screens/ReconcileRefunds'));
const Payouts = lazy(() => import('screens/Payouts'));
const ForReleaseStatements = lazy(() => import('screens/ForReleaseStatements'));
const ReleasedStatements = lazy(() => import('screens/ReleasedStatements'));


var dashRoutes = [
	{
		path: '/dashboard',
		name: 'Home',
		icon: DashboardIcon,
		component: EsqPay,
		layout: '/app',
		pageName: 'Admin',
	},
	{
		access: true,
		collapse: true,
		name: 'Admin',
		icon: AccountCircle,
		state: 'adminCollapse',
		views: [
			{
				path: '/admin-approval',
				name: 'Admin Approval',
				// icon: AccountCircle,
				mini: 'AA',
				component: AdminApproval,
				layout: '/app',
				access: true,
				pageName: 'Admin',
			},
			{
				path: '/roles',
				name: 'Manage Roles',
				icon: FaceIcon,
				component: Roles,
				layout: '/app',
				access: true,
				pageName: 'Manage Roles',
			},
			{
				path: '/doc-classes',
				name: 'Document Classes - Admin',
				mini: 'DC',
				component: DocumentClass,
				layout: '/app',
				access: true,
				pageName: 'Document Classes - Admin',
			},
			{
				path: '/doc-types',
				name: 'Document Types - Admin',
				// icon: WorkIcon,
				mini: 'DT',
				component: DocTypes,
				layout: '/app',
				access: true,
				pageName: 'Document Types - Admin',
			},
			{
				path: '/document-classes-notary',
				name: 'Document Classes (Notary)',
				// icon: WorkIcon,
				mini: 'DCN',
				component: DocumentClassNotary,
				layout: '/app',
				access: true,
				pageName: 'Document Types - Admin',
			},
			// {
			// 	path: '/assignfeesrates',
			// 	name: 'Notary Fees/Rates',
			// 	icon: Money,
			// 	component: FeesRatesNew,
			// 	layout: '/app',
			// 	access: true,
			// },
			// {
			// 	path: '/feesratesadmin',
			// 	name: 'Notary Fees/Rates - Admin',
			// 	// icon: Money,
			// 	mini: 'FE',
			// 	component: FeesRates,
			// 	layout: '/app',
			// 	access: true,
			// },
			{
				path: '/signupweb',
				name: 'Register Notary',
				icon: PersonAdd,
				// color: whiteColor,
				// bgColor: primaryColor[1],
				component: SignUpWeb,
				layout: '/app',
				access: true,
				pageName: 'Sign-Up',
			},
			{
				path: '/signupadmin',
				name: 'Register New Admin',
				icon: PersonAdd,
				// color: whiteColor,
				// bgColor: primaryColor[1],
				component: SignUpWebAdmin,
				layout: '/app',
				access: true,
				pageName: 'Sign-Up',
			},
		],
	},

	{
		path: '/members',
		name: 'OCC Verification',
		icon: FaceIcon,
		component: Members,
		layout: '/app',
		access: true,
		pageName: 'Sign-Up',
	},
	{
		path: '/documents-sorter',
		name: 'Documents (Sorter)',
		icon: WorkIcon,
		component: DocumentsSorter,
		layout: '/app',
		access: true,
		pageName: 'Documents (Sorter)',
	},
	{
		path: '/documents-supervisor',
		name: 'Documents (Supervisor)',
		icon: WorkIcon,
		component: DocumentsSupervisor,
		layout: '/app',
		access: true,
		pageName: 'Documents (Supervisor)',
	},
	{
		access: true,
		collapse: true,
		name: 'For Recon',
		icon: Payment,
		state: 'reconCollapse',
		views: [
			{
				path: '/recon-paymongoupload',
				name: 'Upload Paymongo file',
				// icon: Payment,
				mini: 'UF',
				component: PaymentReconUpload,
				layout: '/app',
				pageName: 'Paymongo File Upload',
			},
			{
				path: '/unmatched-paymongo',
				name: 'Unmatched payments',
				// icon: Payment,
				mini: 'UR',
				component: PaymentReconUnmatched,
				layout: '/app',
				pageName: 'Unmatched Paymongo Records',
			},
			{
				path: '/recon-all',
				name: 'All',
				// icon: Payment,
				mini: 'A',
				component: PaymentReconALL,
				layout: '/app',
				pageName: 'Payment Reconciliation',
			},
			{
				path: '/recon-notarized',
				name: 'Notarized',
				// icon: Payment,
				mini: 'N',
				component: PaymentReconNOT,
				layout: '/app',
				pageName: 'Payment Reconciliation',
			},
			{
				path: '/recon-paid',
				name: 'Paid',
				// icon: Payment,
				mini: 'P',
				component: PaymentReconPAID,
				layout: '/app',
				pageName: 'Payment Reconciliation',
			},
			{
				path: '/recon-cancelled',
				name: 'Cancelled',
				// icon: Payment,
				mini: 'C',
				component: PaymentReconCANCELLED,
				layout: '/app',
				pageName: 'Payment Reconciliation',
			},
			{
				path: '/recon-denied',
				name: 'Denied',
				// icon: Payment,
				mini: 'D',
				component: PaymentReconDENIED,
				layout: '/app',
				pageName: 'Payment Reconciliation',
			},
      {
				path: '/recon-refunds',
				name: 'Processed Refunds',
				// icon: Payment,
				mini: 'R',
				component: ReconcileRefunds,
				layout: '/app',
				pageName: 'Refund Reconciliation',
			},
		],
	},
	{
		access: true,
		collapse: true,
		name: 'Reconciled',
		icon: BookIcon,
		state: 'bookingsCollapse',
		views: [
			{
				path: '/bookings-notarized',
				name: 'For payment',
				// icon: Payment,
				mini: 'NB',
				component: BookingsNotarized,
				layout: '/app',
				pageName: 'Notarized Bookings (with Reconciled Payments)',
			},
			{
				path: '/bookings-pending',
				name: 'Pending',
				// icon: Payment,
				mini: 'PB',
				component: BookingsPending,
				layout: '/app',
				pageName: 'Pending Bookings (with Reconciled Payments)',
			},
			{
				path: '/bookings-cancelled',
				name: 'For refund - cancelled',
				// icon: Payment,
				mini: 'CB',
				component: BookingsCancelled,
				layout: '/app',
				pageName: 'Cancelled Bookings (with Reconciled Payments)',
			},
			{
				path: '/booking-denied',
				name: 'For refund - denied',
				// icon: Payment,
				mini: 'DB',
				component: BookingsDenied,
				layout: '/app',
				pageName: 'Denied Bookings (with Reconciled Payments)',
			},
		],
	},
	// {
	//   access: true,
	//   collapse: true,
	//   name: "For Refund Cancelled",
	//   icon: BookIcon,
	//   state: "refundsCollapse",
	//   views: [
	//     {
	//       path: "/bookings-cancelled",
	//       name: "For Refund - Cancelled",
	//       // icon: Payment,
	//       mini: "CB",
	//       component: BookingsCancelled,
	//       layout: "/app",
	//     },
	//     {
	//       path: "/booking-denied",
	//       name: "For Refund - Denied",
	//       // icon: Payment,
	//       mini: "DB",
	//       component: BookingsDenied,
	//       layout: "/app",
	//     },
	//   ]
	// },
	// {
	//   path: "/paynotaries",
	//   name: "Notary Payments",
	//   icon: Payment,
	//   component: NotaryPayments,
	//   layout: "/app",
	//   access: true
	// },
	{
		access: true,
		collapse: true,
		name: 'Payouts',
		icon: Assignment,
		state: 'statmentsCollapse',
		views: [
			{
				path: '/paystatements',
				name: 'Statements',
				mini: 'S',
				component: NotaryStatements,
				layout: '/app',
				pageName: 'Statements',
			},
			// {
			// 	path: '/paynewstatements',
			// 	name: 'Payouts',
			// 	mini: 'P',
			// 	component: NotaryNewStatements,
			// 	layout: '/app',
			// 	pageName: 'For Payout',
			// },
      {
				path: '/payouts',
				name: 'Payouts',
				mini: 'P',
				component: Payouts,
				layout: '/app',
				pageName: 'For Payout',
			},
      {
				path: '/release-statements',
				name: 'For release',
				mini: 'RS',
				component: ForReleaseStatements,
				layout: '/app',
				pageName: 'Release Statements',
			},
			{
				path: '/released-payouts',
				name: 'Released',
				mini: 'R',
				component: ReleasedStatements,
				layout: '/app',
				pageName: 'Released Payouts',
			},
		],
	},
	{
		access: true,
		collapse: true,
		name: 'Deposits (Paymongo)',
		icon: Payment,
		state: 'depositsCollapse',
		views: [
			{
				path: '/deposits-paymongoupload',
				name: 'Upload Paymongo file',
				// icon: Payment,
				mini: 'UF',
				component: DepositReconUpload,
				layout: '/app',
				pageName: 'Paymongo Deposits File Upload',
			},
			{
				path: '/unmatched-deposits',
				name: 'Unmatched payments',
				// icon: Payment,B
				mini: 'UR',
				component: DepositReconUnmatched,
				layout: '/app',
				pageName: 'Unmatched Paymongo Deposits',
			},
			{
				path: '/matched-deposits',
				name: 'With deposit',
				// icon: Payment,
				mini: 'WD',
				component: DepositReconMatched,
				layout: '/app',
				pageName: 'With Deposits',
			},
			{
				path: '/xmatched-deposits',
				name: 'Without deposit',
				// icon: Payment,
				mini: 'XD',
				component: DepositPaymentUnmatched,
				layout: '/app',
				pageName: 'Without Deposits',
			},
		],
	},
  {
		access: true,
		collapse: true,
		name: 'Refund',
		icon: Payment,
		state: 'refundCollapse',
		views: [
      {
				path: '/refund-payments',
				name: 'Refund Payments',
				// icon: Payment,
				mini: 'RP',
				component: RefundPayments,
				layout: '/app',
				pageName: 'Refund Payments',
			},
			{
				path: '/refund-stubs',
				name: 'Refund Attempts',
				// icon: Payment,
				mini: 'RA',
				component: RefundAttempts,
				layout: '/app',
				pageName: 'Refund Attempts',
			},
      {
				path: '/processed-refunds',
				name: 'Processed Refunds',
				// icon: Payment,
				mini: 'PR',
				component: ProcessedRefunds,
				layout: '/app',
				pageName: 'Processed Refunds',
			},
		],
	},
	{
		access: true,
		collapse: true,
		name: 'Export Data',
		icon: Payment,
		state: 'exportDataCollapse',
		views: [
			{
				path: '/export-payments',
				name: 'Payments',
				// icon: Payment,
				mini: 'PY',
				component: ExportPayments,
				layout: '/app',
				pageName: 'Export Payments',
			},
		],
	},
	{
		path: '/account',
		name: 'Account Management',
		icon: AccountBox,
		component: Account,
		layout: '/app',
		access: true,
		pageName: 'Account',
	},
	{
		path: '/signout',
		name: 'Sign out',
		icon: ExitToApp,
		component: SignOutFirebase,
		layout: '/app',
		pageName: 'Sign Out',
	},
];
export default dashRoutes;
