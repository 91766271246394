import { lazy } from 'react';


import FaceIcon from "@material-ui/icons/Face";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ExitToApp from "@material-ui/icons/ExitToApp";
import WorkIcon from '@material-ui/icons/Work';

const SignOutFirebase = lazy(() => import("screens/SignOutFirebase"))
const EsqPay = lazy(() => import("EsqPay"))
const Account = lazy(() => import("screens/Account"))

const Members = lazy(() => import("screens/members"))
const AdminApproval = lazy(() => import("screens/admin"))
const DocumentTypes = lazy(() => import("screens/DocumentTypes2"))
const Documents = lazy(() => import("screens/DocumentsSorter"))

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Home",
    icon: DashboardIcon,
    component: EsqPay,
    layout: "/app",
  },
  {
    path: "/documents",
    name: "Documents",
    icon: WorkIcon,
    component: Documents,
    layout: "/app",
  },
  {
    path: "/account",
    name: "Account management",
    icon: FaceIcon,
    component: Account,
    layout: "/app",
  },
  {
    path: "/signout",
    name: "Sign out",
    icon: ExitToApp,
    component: SignOutFirebase,
    layout: "/app",
  },
];
export default dashRoutes;
