import React, { useEffect } from 'react'
import { useAuthUser } from "utils/AuthUserProvider";
import queryString from "query-string";

const DocumentClassificationRedirect = (props) => {
  const { client, history } = props
  useEffect(() => {
    //check if signed in? if not prompt to sign in?
    var features = JSON.parse(localStorage.getItem("features"))
    console.log(features)
    if(features){
      const sorterRoles = features?.filter(r => r === "Documents (Sorter)" || r === "Documents (Supervisor)")
      if(sorterRoles.length > 0){
        console.log(sorterRoles)
        if(sorterRoles.includes("Documents (Supervisor)")){
          history.push("/app/documents-supervisor")
          console.log("SUPERVISOR")
        }
        else if(sorterRoles?.length === 1 && sorterRoles[0] === "Documents (Sorter)"){
          history.push("/app/documents-sorter")
          console.log("SORTER")
        }
        else{
          // history.push("/")
        }
      }
    }
    else{
      history.push("/auth/signin")
    }
  }, [])

  return (
    <div>
      
    </div>
  )
}

export default DocumentClassificationRedirect
