import React, { createContext, useState, useContext } from 'react';

const LocalStateContext = createContext();
const LocalStateProvider = LocalStateContext.Provider;

function SearchProvider({ children }) {
  const [gridApi, setGridApi] = useState(null)
  const [rowData, setRowData] = useState([]);
  const [gridRowData, setGridRowData] = useState([]);

  const [member, setMember] = useState(null);
  const [admin, setAdmin] = useState(null);
  const [openMemberUpdate, setOpenMemberUpdate] = useState(false);
  const [openAdminUpdate, setOpenAdminUpdate] = useState(false);
  const [refresh, setRefresh] = useState(0)
  const [filters, setFilters] = useState(null)

  const [role, setRole] = useState(null);
  const [openRoleUpdate, setOpenRoleUpdate] = useState(false)

  const [refreshRoles, setRefreshRoles] = useState(0)
  const [openAdminApproval, setOpenAdminApproval] = useState(true)
  const [openRole, setOpenRole] = useState(true)

  const [openFeesRates, setOpenFeesRates] = useState(true)
  const [openFeesRatesUpdate, setOpenFeesRatesUpdate] = useState(false)

  const [openDocTypes, setOpenDocTypes] = useState(true)
  const [openDocTypesUpdate, setOpenDocTypesUpdate] = useState(false)


  const [rowDataFeesRates, setRowDataFeesRates] = useState([]);
  const [gridRowDataFeesRates, setGridRowDataFeesRates] = useState([]);

  const [level1FeesRates, setLevel1FeesRates] = useState("");
  const [membersArray, setMembersArray] = useState([]);

  const [memberId, setMemberId] = useState("")

  const [valueMember, setValueMember] = useState([{
    id: 'ALL', name: 'ALL', value: 'ALL'
  }]);
  const [inputValue, setInputValue] = useState();

  const [valueFeesRates, setValueFeesRates] = useState("ALL");
  const [feesRates, setFeesRates] = useState([])

  const [docTypes, setDocTypes] = useState([])
  const [docTypesView, setDocTypesView] = useState([]);

  const [valueDocTypes, setValueDocTypes] = useState("ALL");
  const [valueDocTypesLevel1, setValueDocTypesLevel1] = useState("");

  const [currentDocTypeNode, setCurrentDocTypeNode] = useState(null);
  const [refreshDocs, setRefreshDocs] = useState(0)

  return (
    <LocalStateProvider
      value={{
        gridApi, setGridApi,
        rowData, setRowData,
        gridRowData, setGridRowData,
        filters, setFilters,
        member, setMember,
        admin, setAdmin,
        refresh, setRefresh,
        openMemberUpdate, setOpenMemberUpdate,
        openAdminUpdate, setOpenAdminUpdate,
        role, setRole,
        openRoleUpdate, setOpenRoleUpdate,
        refreshRoles, setRefreshRoles,
        openAdminApproval, setOpenAdminApproval,
        openRole, setOpenRole,
        openFeesRates, setOpenFeesRates,
        openFeesRatesUpdate, setOpenFeesRatesUpdate,
        rowDataFeesRates, setRowDataFeesRates,
        gridRowDataFeesRates, setGridRowDataFeesRates,
        level1FeesRates, setLevel1FeesRates,
        membersArray, setMembersArray,
        memberId, setMemberId,
        valueMember, setValueMember,
        inputValue, setInputValue,
        valueFeesRates, setValueFeesRates,
        feesRates, setFeesRates,
        openDocTypes, setOpenDocTypes,
        openDocTypesUpdate, setOpenDocTypesUpdate,
        docTypes, setDocTypes,
        docTypesView, setDocTypesView,
        valueDocTypes, setValueDocTypes,
        valueDocTypesLevel1, setValueDocTypesLevel1,
        currentDocTypeNode, setCurrentDocTypeNode,
        refreshDocs, setRefreshDocs

      }}
    >
      {children}
    </LocalStateProvider >
  );
}

function useSearch() {
  const all = useContext(LocalStateContext);
  return all;
}

export { SearchProvider, useSearch };
