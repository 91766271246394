import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withFirebase } from 'utils/Firebase';
import { withAuthentication } from 'utils/Session';
import Button from '@material-ui/core/Button';
import { gql } from '@apollo/client';
import useWindowSize from 'utils/useWindowSize';

import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle.js';

class SignOutButton extends React.Component {

  render() {
    const { firebase } = this.props;
    const { classes } = this.props;

    return (
      <Button
        // href="/signoutthankyou"
        color={window.innerWidth < 960 ? 'primary' : 'primary'}
        //target="_blank"
        className={classes.navButton}
        onClick={() => {
          debugger;
          firebase.doSignOut();

        }}
        size="md"
        variant="contained"

      >
        Sign Out
      </Button>
    );
  }
}

export const LOGADMIN_OUT = gql`
  mutation logAdminOut($email: String!, $tokenId: String) {
    logAdminOut(email: $email, tokenId: $tokenId) {
      id
    }
  }
`;

SignOutButton.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withAuthentication(withFirebase(withStyles(presentationStyle)(SignOutButton)));
