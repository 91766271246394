import React from 'react';
import ReactDOM from 'react-dom';

import { SearchProvider } from './utils/Search'
import { DocumentProvider } from './utils/Documents'
import { DocumentsProvider } from './utils/DocumentContext'
import { AuthUserProvider } from './utils/AuthUserProvider'
import { FeaturesProvider } from './utils/FeaturesProvider'

import { ApolloProvider } from '@apollo/client';

import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { onError } from "@apollo/client/link/error";

import Main from './screens/Main';
import Firebase, { FirebaseContext } from './utils/Firebase';

import { SnackbarProvider } from 'notistack';

import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";

console.log("............I N D E X..................");
console.log(process.env.NODE_ENV);
console.log("process.env.REACT_APP_endpoint");
console.log(process.env.REACT_APP_endpoint);

const token = localStorage.getItem("token");
console.log(`token`)
console.log(token)
console.log(`REACT_APP_PRISMA_SECRET`)
console.log(process.env.REACT_APP_PRISMA_SECRET)

const httpLink = createHttpLink({
  uri:
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_endpoint
      : process.env.REACT_APP_prodEndpoint
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  console.log(`token`)
  console.log(token)
  return {
    headers: {
      ...headers,
      userAuthorization: token ? `Bearer ${token}` : '',
      authorization: `Bearer ${process.env.REACT_APP_PRISMA_SECRET}`
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      console.log("****************************");
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
  }

  if (networkError) {
    console.log("*****************************");
    console.log(`[Network error]: ${networkError}`);
  }
});



const client = new ApolloClient({
  link: authLink.concat(errorLink.concat(httpLink)),
  cache: new InMemoryCache(),
});

var hist = createBrowserHistory();

// console.log("client *****************************");
// console.log(link);

// LicenseManager.setLicenseKey(
//   // 'Evaluation_License_Not_For_Production_17_December_2019__MTU3NjU0MDgwMDAwMA==579adc0faf540764c592ca89db91dfd2'
//   'For_Trialing_ag-Grid_Only-Not_For_Real_Development_Or_Production_Projects-Valid_Until-1_April_2021_[v2]_MTYxNzIzMTYwMDAwMA==6ba158d7d8f3eb928a75a403e217b9fc'
// );

ReactDOM.render(
  <AuthUserProvider>
    <Router history={hist}>
      <FirebaseContext.Provider value={new Firebase()}>
        <ApolloProvider client={client}>
          <SearchProvider>
            <DocumentProvider>
              <DocumentsProvider>
                <FeaturesProvider>
                  <Main client={client} />
                </FeaturesProvider>
              </DocumentsProvider>
            </DocumentProvider>
          </SearchProvider>
        </ApolloProvider>
      </FirebaseContext.Provider>
    </Router>
  </AuthUserProvider>
  ,
  document.getElementById('root')
);
