import React, { createContext, useState, useContext } from 'react';

const LocalStateContext = createContext();
const LocalStateProvider = LocalStateContext.Provider;

function AuthUserProvider({ children }) {
  const [authUserContext, setAuthUserContext] = useState(null)

  return (

    <LocalStateProvider
      value={{
        authUserContext, setAuthUserContext
      }}
    >
      {children}
    </LocalStateProvider>
  );
}

function useAuthUser() {
  const all = useContext(LocalStateContext);
  return all;
}

export { AuthUserProvider, useAuthUser };

