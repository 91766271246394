import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/firestore";

const prodConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

const devConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

// var prodConfig = {
//   apiKey: "AIzaSyAIBR5cyLMLHvGEtXOGcw-yjYpTpzdTPsA",
//   authDomain: "notarynetphils.firebaseapp.com",
//   databaseURL: "https://notarynetphils.firebaseio.com",
//   projectId: "notarynetphils",
//   storageBucket: "notarynetphils.appspot.com",
//   messagingSenderId: "928487731149",
//   appId: "1:928487731149:web:f65e75dc6e5f823323de84",
//   measurementId: "G-S6TMN6YGPN"
// };

// var devConfig = {
//   apiKey: "AIzaSyAIBR5cyLMLHvGEtXOGcw-yjYpTpzdTPsA",
//   authDomain: "notarynetphils.firebaseapp.com",
//   databaseURL: "https://notarynetphils.firebaseio.com",
//   projectId: "notarynetphils",
//   storageBucket: "notarynetphils.appspot.com",
//   messagingSenderId: "928487731149",
//   appId: "1:928487731149:web:f65e75dc6e5f823323de84",
//   measurementId: "G-S6TMN6YGPN"
// };

//firebase.initializeApp(devConfig);

const config = process.env.NODE_ENV === "production" ? prodConfig : devConfig;

class Firebase {
  constructor() {
    app.initializeApp(config);

    /* Helper */

    this.serverValue = app.database.ServerValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */

    this.auth = app.auth();
    this.db = app.database();
    this.storage = app.storage();
    this.firestore = app.firestore();

    this.documentStorage = app.app().storage(process.env.REACT_APP_STORAGE_BUCKET)
    /* Social Sign In Method Provider */

    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();
    this.twitterProvider = new app.auth.TwitterAuthProvider();
    this.appleProvider = new app.auth.OAuthProvider('apple.com')
    this.appleProvider.addScope('email');
    this.appleProvider.addScope('name');
    // this.justSignedUp = false
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doCreateUserWithEmailAndPasswordForUser = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () => this.auth.signInWithPopup(this.facebookProvider);

  doSignInWithTwitter = () => this.auth.signInWithPopup(this.twitterProvider);

  doSignInWithApple = () => this.auth.signInWithPopup(this.appleProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
    });

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  doEmailUpdate = (email) =>
    this.auth.currentUser.updateEmail(email);
  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      debugger;
      if (authUser) {
        this.user(authUser.uid)
          .once("value")
          .then((snapshot) => {
            const dbUser = snapshot.val();

            // default empty roles
            if (dbUser && !dbUser.roles) {
              dbUser.roles = [];
            }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            };

            // next(this.justSignedUp ? null : authUser);
            next(authUser);
          });
      } else {
        fallback();
      }
    });

  // *** User API ***

  user = (uid) => this.db.ref(`users/${uid}`);

  users = () => this.db.ref("users");

  // *** Message API ***

  message = (uid) => this.db.ref(`messages/${uid}`);

  messages = () => this.db.ref("messages");
}

{
  /*
  Firebase Settings - BillyouNow
  
  https://console.firebase.google.com/u/0/project/legal-assistant-ph/settings/serviceaccounts/adminsdk
  Firebase Authentication
  https://firebase.google.com/docs/auth
  Authenticate with Firebase using Password-Based Accounts using Javascript
  https://firebase.google.com/docs/auth/web/password-auth
  
  Add the Firebase Admin SDK to your server
  https://firebase.google.com/docs/admin/setup
  Manage Users in Firebase
  https://firebase.google.com/docs/auth/web/manage-users
  Manage Users in Firebase
  https://firebase.google.com/docs/auth/admin/manage-users
  Where do I start with Firebase Authentication?
  https://firebase.google.com/docs/auth/where-to-start
*/
}
export default Firebase;
