import { gql } from '@apollo/client';

export const MEMBER_CHECK = gql`
  query memberCheck($email: String) {
    memberCheck(email: $email) {
      id
      email
    }
  }
`;

export const ADMIN_CHECK = gql`
  query adminCheck($email: String) {
    adminCheck(email: $email) {
      id
      email
    }
  }
`;

export const MEMBER_CHECK_CONTACT = gql`
  query memberCheck($email: String) {
    memberCheck(email: $email) {
      id
      email
      mobileNumber
    }
  }
`;

export const ADMIN_CHECK_CONTACT = gql`
  query adminCheck($email: String) {
    adminCheck(email: $email) {
      id
      email
      mobileNumber
    }
  }
`;

export const MEMBER_CHECK1 = gql`
  query memberCheck1($email: String) {
    memberCheck1(email: $email) {
      id
      email
    }
  }
`;

export const MEMBER_CHECKV2 = gql`
  query memberCheck($email: String) {
    memberCheck(email: $email) {
      id
      email
      displayName
      hasConfirmedAgreement
      hasRespondedToInvite
      hasInitiallyLoggedIn
      isLoggedIn
      lastName
      firstName
      showName
      showAddress
      showEmail
      showLandline
      showMobileNumber
      showDays
      showHours
      monday
      mondayFrom
      mondayTo
      tuesday
      tuesdayFrom
      tuesdayTo
      wednesday
      wednesdayFrom
      wednesdayTo
      thursday
      thursdayFrom
      thursdayTo
      friday
      fridayFrom
      fridayTo
      saturday
      saturdayFrom
      saturdayTo
      sunday
      sundayFrom
      sundayTo
      streetNumber
      barangay
      cityMunicipality
      province
      city
      zipCode
      lat
      lng
      serialNumber
      cityProvince
      dateGranted
      expiryDate
      mobileNumber
      landLine
      facebookLink
      twitterLink
      instagramLink
      rollNumber
      subCityMunicipality
      status
    }
  }
`;

export const MEMBERS_QUERY = gql`
  query getMembers {
    getMembers {
      id
      email
      lastName
      firstName
      middleName
      mobileNumber
      expiryDate
      documentServices {
        id
      }
      userSearchTxes {
        status
      }
    }
  }
`;

export const LEVEL1_QUERY = gql`
  query getDocumentTopLevels {
    getDocumentTopLevels {
      id
      ord
      documentTypeLevel1
    }
  }
`;

export const GET_DOCTYPES_QUERY = gql`
  query getDocTypes {
    getDocTypes {
      dtl_ID
      ord
      documentTypeLevel1
      ord_dt
      documentTypeLevel2
      displayAmount
      dt_ID
      enabled
      onlyForSorters
      ds_count
      dt_count
    }
  }
`;

export const GET_FEES_RATES_QUERY = gql`
  query getFeesRates($memberId: ID!) {
    getFeesRates(memberId: $memberId) {
      enabledForNotary
      ord
      dtl_ID
      documentTypeLevel1
      ord_dt
      documentTypeLevel2
      dt_ID
      enabledAsLevel2
      onlyForSorters
      displayAmount
      ds_ID
      member_ID
      isPercentage
      rate
      fixedFee
      email
      firstName
      middleName
      lastName
      expiryDate
    }
  }
`;

export const GET_BOOKINGS_FOR_PAYMENT_QUERY = gql`
  query getBookingsForPayment {
    getBookingsForPayment {
      transactionId
      invoiceNo
      bookingDate
      statusDate
      status
      documentTypeLevel1
      documentTypeLevel2
      documentAmount
      paymentMethod
      paymentDate
      paymentRefId
      last4
      brand
      paymentAmount
      commission
      netAmount
      fee
      foreignFee
      payableToMember
      receiptNo_PayableToMember
      netIncome
      taxDue
      revenue
      receiptNo_ToPrincipal
      memberId
      notaryEmail
      notaryContactNumber
      notaryFirstName
      notaryMiddleName
      notaryLastName
      n_StreetNumber
      n_Barangay
      n_CityMunicipality
      n_City
      n_Province
      customerEmail
      customerContactNumber
      firstName
      middleName
      lastName
      streetNumber
      barangay
      cityMunicipality
      city
      province
      id
    }
  }
`;

export const GET_PAYMENTS_FOR_RECON = gql`
  query getPaymentsForRecon($status: String, $reconciled: Boolean) {
    getPaymentsForRecon(status: $status, reconciled: $reconciled) {
      transactionId
      invoiceNo
      bookingDate
      statusDate
      status
      documentTypeLevel1
      documentTypeLevel2
      documentAmount
      paymentMethod
      paymentDate
      paymentRefId
      last4
      brand
      paymentAmount
      commission
      netAmount
      fee
      foreignFee
      payableToMember
      receiptNo_PayableToMember
      netIncome
      taxDue
      revenue
      receiptNo_ToPrincipal
      memberId
      notaryEmail
      notaryContactNumber
      notaryFirstName
      notaryMiddleName
      notaryLastName
      n_StreetNumber
      n_Barangay
      n_CityMunicipality
      n_City
      n_Province
      customerEmail
      customerContactNumber
      firstName
      middleName
      lastName
      streetNumber
      barangay
      cityMunicipality
      city
      province
      id
    }
  }
`;

export const ADMIN_CHECKV2 = gql`
  query adminCheck($email: String) {
    adminCheck(email: $email) {
      id
      email
      displayName
      isLoggedIn
      lastName
      firstName
      mobileNumber
      landLine
    }
  }
`;

export const xxCLIENTS_BY_EMAIL_QUERY = gql`
  query ClientsByEmail($email: String) {
    clientsByEmail(email: $email) {
      member {
        id
      }
      id
      lastName
      firstName
      company
      status
      ord
      parentId
      cases {
        id
        caseCode
        caseTitle
        status
        ord
        parentId
        member {
          id
        }
        activityLog {
          id
          date
          billingType
          hours
          hourlyRate
          activityLogType
          activityLogSubType
          activityLogSubSubType
          amount
          fixedRate
          notes
          startTime
          endTime
          actionDate
          action
          status
          ord
          parentId
        }
        expenseLog {
          id
          date
          billingType
          hours
          hourlyRate
          expenselogType
          amount
          fixedRate
          notes
          startTime
          endTime
          actionDate
          action
          status
          ord
          parentId
        }
      }
    }
  }
`;

export const CLIENTS_BY_EMAIL_QUERY = gql`
  query ClientsByEmailCaseDate(
    $email: String
    $startDate: DateTime
    $endDate: DateTime
  ) {
    clientsByEmailCaseDate(
      email: $email
      startDate: $startDate
      endDate: $endDate
    ) {
      member {
        id
      }
      id
      lastName
      firstName
      company
      status
      ord
      parentId
      cases {
        id
        caseCode
        caseTitle
        status
        ord
        parentId
        member {
          id
        }
        activityLog {
          id
          date
          billingType
          hours
          hourlyRate
          activityLogType
          activityLogSubType
          activityLogSubSubType
          amount
          fixedRate
          notes
          startTime
          endTime
          actionDate
          action
          status
          ord
          parentId
        }
        expenseLog {
          id
          date
          billingType
          hours
          hourlyRate
          expenselogType
          amount
          fixedRate
          notes
          startTime
          endTime
          actionDate
          action
          status
          ord
          parentId
        }
      }
    }
  }
`;

export const CASES_BY_CLIENT_QUERY = gql`
  query casesByClient($clientId: ID!) {
    casesByClient(clientId: $clientId) {
      id
      caseCode
      caseTitle
      status
      ord
      parentId
      member {
        id
      }
      client {
        id
      }
      activityLog {
        id
        date
        billingType
        hours
        hourlyRate
        activityLogType
        activityLogSubType
        activityLogSubSubType
        amount
        fixedRate
        notes
        startTime
        endTime
        actionDate
        action
        status
        ord
        parentId
      }
      expenseLog {
        id
        date
        billingType
        hours
        hourlyRate
        expenselogType
        amount
        fixedRate
        notes
        startTime
        endTime
        actionDate
        action
        status
        ord
        parentId
      }
    }
  }
`;

export const CASES_BY_CLIENT_NOLOGS_QUERY = gql`
  query casesByClient($clientId: ID!) {
    casesByClient(clientId: $clientId) {
      id
      caseCode
      caseTitle
      status
      ord
    }
  }
`;

export const MEMBER_CHECK_V2 = gql`
  query memberCheck($email: String) {
    memberCheck(email: $email) {
      id
      email
    }
  }
`;

export const CLIENT_FILTER = gql`
  query clientFilter($filter: String, $memberId: ID!) {
    clientFilter(filter: $filter, memberId: $memberId) {
      member {
        id
      }
      id
      lastName
      firstName
      company
      status
      ord
      parentId
      cases {
        id
        caseCode
        caseTitle
        status
        ord
        parentId
        member {
          id
        }
        activityLog {
          id
          date
          billingType
          hours
          hourlyRate
          activityLogType
          activityLogSubType
          activityLogSubSubType
          amount
          fixedRate
          notes
          startTime
          endTime
          actionDate
          action
          status
          ord
          parentId
        }
        expenseLog {
          id
          date
          billingType
          hours
          hourlyRate
          expenselogType
          amount
          fixedRate
          notes
          startTime
          endTime
          actionDate
          action
          status
          ord
          parentId
        }
      }
    }
  }
`;

export const CASE_FILTER = gql`
  query casesFilter($filter: String, $clientId: ID!) {
    casesFilter(filter: $filter, clientId: $clientId) {
      id
      caseCode
      caseTitle
      status
      ord
      parentId
      member {
        id
      }
      client {
        id
      }
      activityLog {
        id
        date
        billingType
        hours
        hourlyRate
        activityLogType
        activityLogSubType
        activityLogSubSubType
        amount
        fixedRate
        notes
        startTime
        endTime
        actionDate
        action
        status
        ord
        parentId
      }
      expenseLog {
        id
        date
        billingType
        hours
        hourlyRate
        expenselogType
        amount
        fixedRate
        notes
        startTime
        endTime
        actionDate
        action
        status
        ord
        parentId
      }
    }
  }
`;

export const CLIENTS_BY_MEMBERNAME_QUERY = gql`
  query ClientsByMemberName(
    $memberId: ID!
    $lastName: String
    $firstName: String
  ) {
    clientsByMemberName(
      memberId: $memberId
      lastName: $lastName
      firstName: $firstName
    ) {
      id
      lastName
      firstName
      company
      status
    }
  }
`;

export const CLIENTS_BY_MEMBER_QUERY = gql`
  query ClientsByMember($memberId: ID!) {
    clientsByMember(memberId: $memberId) {
      id
      lastName
      firstName
      company
      parentId
      status
      ord
    }
  }
`;

export const IS_BYN_MEMBER_QRY = gql`
  query bynCheck($email: String!) {
    bynCheck(email: $email) {
      email
    }
  }
`;

export const TICKETS_QUERY = gql`
  query {
    tickets {
      id
      ticketNumber
      name
      message
      dateCreation
      dateCompletion
      status
    }
  }
`;

export const NOTARY_QUERY = gql`
  query getNotaries(
    $province: String
    $cityMunicipality: String
    $subCityMunicipality: String
    $barangay: String
  ) {
    getNotaries(
      province: $province
      cityMunicipality: $cityMunicipality
      subCityMunicipality: $subCityMunicipality
      barangay: $barangay
    ) {
      firstName
      middleName
      lastName
      streetNumber
      barangay
      cityMunicipality
      subCityMunicipality
      province
      city
      zipCode
      lat
      lng
      serialNumber
      mobileNumber
      landLine
      facebookLink
      twitterLink
      instagramLink
      email
      showName
      showAddress
      showEmail
      showLandline
      showMobileNumber
      showDays
      showHours
      monday
      mondayFrom
      mondayTo
      tuesday
      tuesdayFrom
      tuesdayTo
      wednesday
      wednesdayFrom
      wednesdayTo
      thursday
      thursdayFrom
      thursdayTo
      friday
      fridayFrom
      fridayTo
      saturday
      saturdayFrom
      saturdayTo
      sunday
      sundayFrom
      sundayTo
      status
    }
  }
`;
export const NOTARY_LATLNG_QUERY = gql`
  query getNotariesLatLng(
    $lat1: Float
    $lat2: Float
    $lng1: Float
    $lng2: Float
  ) {
    getNotariesLatLng(lat1: $lat1, lat2: $lat2, lng1: $lng1, lng2: $lng2) {
      firstName
      middleName
      lastName
      streetNumber
      barangay
      cityMunicipality
      subCityMunicipality
      province
      city
      zipCode
      lat
      lng
      serialNumber
      mobileNumber
      landLine
      facebookLink
      twitterLink
      instagramLink
      email
      showName
      showAddress
      showEmail
      showLandline
      showMobileNumber
      showDays
      showHours
      monday
      mondayFrom
      mondayTo
      tuesday
      tuesdayFrom
      tuesdayTo
      wednesday
      wednesdayFrom
      wednesdayTo
      thursday
      thursdayFrom
      thursdayTo
      friday
      fridayFrom
      fridayTo
      saturday
      saturdayFrom
      saturdayTo
      sunday
      sundayFrom
      sundayTo
      status
    }
  }
`;

export const SEARCHVIEWS_WEEK_COUNT = gql`
  query searchViewsCountWeek(
    $emailMobile: String
    $dateFrom: DateTime
    $dateTo: DateTime
  ) {
    searchViewsCountWeek(
      emailMobile: $emailMobile
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      yyww
      count
    }
  }
`;

export const SEARCHVIEWS_MONTH_COUNT = gql`
  query searchViewsCountMonth(
    $emailMobile: String
    $dateFrom: DateTime
    $dateTo: DateTime
  ) {
    searchViewsCountMonth(
      emailMobile: $emailMobile
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      yyww
      count
    }
  }
`;
export const SEARCHVIEWS_YEAR_COUNT = gql`
  query searchViewsCountYear(
    $emailMobile: String
    $dateFrom: DateTime
    $dateTo: DateTime
  ) {
    searchViewsCountYear(
      emailMobile: $emailMobile
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      yyww
      count
    }
  }
`;

export const SEARCHVIEWS_QUERY = gql`
  query searchViews(
    $emailMobile: String
    $dateFrom: DateTime
    $dateTo: DateTime
  ) {
    searchViews(
      emailMobile: $emailMobile
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      email
      date
    }
  }
`;

export const GETVIEWS_DAYS_COUNT = gql`
  query getViewsDaysCount(
    $emailMobile: String
    $dateFrom: DateTime
    $dateTo: DateTime
  ) {
    getViewsDaysCount(
      emailMobile: $emailMobile
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      date
      count
    }
  }
`;

export const GETVIEWS_DETAIL = gql`
  query getViewsDetail(
    $emailMobile: String
    $dateFrom: DateTime
    $dateTo: DateTime
  ) {
    getViewsDetail(
      emailMobile: $emailMobile
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      province
      city
      count
    }
  }
`;

export const GETVIEWS_WEEKS_COUNT = gql`
  query getViewsWeeksCount(
    $emailMobile: String
    $dateFrom: DateTime
    $dateTo: DateTime
  ) {
    getViewsWeeksCount(
      emailMobile: $emailMobile
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      yyww
      count
    }
  }
`;

export const GETVIEWS_MONTHS_COUNT = gql`
  query getViewsMonthsCount(
    $emailMobile: String
    $dateFrom: DateTime
    $dateTo: DateTime
  ) {
    getViewsMonthsCount(
      emailMobile: $emailMobile
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      date
      count
    }
  }
`;
export const GETSERVICEREQUESTS = gql`
  query getServiceRequests($memberId: ID!) {
    getServiceRequests(memberId: $memberId) {
      id
      date
      dateServed
      dateCancelled
      dateDeletion
      requestType
      notes
      status
    }
  }
`;
export const ADMINS_QUERY = gql`
  query {
    admins {
      id
      email
      lastName
      firstName
      displayName
      photoURL
      isLoggedIn
      logInDate
      mobileNumber
      landLine
      status
      roles {
        id
        roleRef {
          id
          role
          features {
            id
            feature
          }
        }
      }
    }
  }
`;

export const ROLES_QUERY = gql`
  query {
    adminRoles {
      id
      role
      admRoles {
        id
      }
      features {
        id
        feature
      }
    }
  }
`;

export const GET_DOCUMENT_TEMPLATES_SCOPES = gql`
  query {
    getDocumentTemplateAndScopes {
      id
      documentType
      documentTemplateScopes {
        id
        scope
        province
        city
        subCityMunicipality
        minRate
        minFixedFee
        isPercentage
        absoluteMinRate
        absoluteFixedFee
      }
    }
  }
`;

export const GET_DOCUMENT_TEMPLATES = gql`
  query getDocumentTemplateScopes {
    getDocumentTemplateScopes {
      id
      documentType
      documentTypeId
      # documentTemplateScopes {
      #   id
      scope
      province
      city
      subCityMunicipality
      minRate
      minFixedFee
      isPercentage
      absoluteMinRate
      absoluteFixedFee
      # }
    }
  }
`;
export const GET_DOCUMENT_TEMPLATES2 = gql`
  query getDocumentTemplates {
    getDocumentTemplates {
      id
      documentType
      documentTemplateScopes {
        id
        scope
        province
        city
        subCityMunicipality
        minRate
        minFixedFee
        absoluteMinRate
        absoluteFixedFee
        isPercentage
      }
    }
  }
`;

export const GET_PENDING_BOOKINGS2 = gql`
  query pendingBookings {
    pendingBookings {
      id
      date
      status
      transactionId
      invoiceNo
      isReferred
      dateReferred
      dateClassified
    }
  }
`;

export const GET_PENDING_BOOKINGS = gql`
  query pendingBookings {
    getPendingBookings {
      id
      createdAt
      status
      transactionId
      invoiceNo
      isReferred
      dateReferred
      dateClassified
    }
  }
`;

export const GET_BOOKINGS_STATUS = gql`
  query getBookingsByStatus($status: [String!]) {
    getBookingsByStatus(status: $status) {
      id
      date
      status
      transactionId
      invoiceNo
      isReferred
      dateClassified
      dateReferred
      dateDenied
      isLocked
      user {
        id
        email
        mobileNumber
        landLine
      }
      adminClassified {
        id
      }
      adminReferred {
        id
      }
      adminDenied {
        id
      }
      currentSorter {
        id
      }
    }
  }
`;

export const GET_DOCUMENT_FILES = gql`
  query getDocumentUploads($userSearchTextId: ID!) {
    getDocumentUploads(userSearchTextId: $userSearchTextId) {
      id
      ord
      filePath
      fileName
      contentType
    }
  }
`;

export const IS_BOOKING_LOCKED = gql`
  query isBookingLocked($userSearchTextId: ID!) {
    isBookingLocked(userSearchTextId: $userSearchTextId) {
      isLocked
    }
  }
`;

export const GET_DOCUMENT_TEMPLATES_V2 = gql`
  query getDocumentTemplates {
    getDocumentTemplatesV2 {
      id
      documentTypeLevel1
      documentTypeLevel2
      # isPercentage
    }
  }
`;

export const GET_ADMIN_ROLES = gql`
  query getAdminRoles {
    getAdminRoles {
      id
      role
      admRoles {
        id
      }
      features {
        id
        feature
      }
    }
  }
`;

export const GET_FEATURES = gql`
  query getFeatures {
    getFeatures {
      id
      feature
    }
  }
`;

export const ADMIN_LOGIN = gql`
  query adminLogIn($email: String!) {
    adminLogIn(email: $email) {
      id
      email
      roles {
        id
        roleRef {
          id
          role
          features {
            id
            feature
          }
        }
      }
      isLoggedIn
    }
  }
`;

export const GET_CLASSIFIED_BOOKINGS_SORTER = gql`
  query getClassifiedBookings($adminId: ID!) {
    getClassifiedBookings(adminId: $adminId) {
      id
      date
      status
      transactionId
      invoiceNo
      isReferred
      dateReferred
      dateClassified
      dateDenied
      user {
        id
        email
      }
      adminClassified {
        id
      }
      adminReferred {
        id
      }
      currentSorter {
        id
      }
      isLocked
    }
  }
`;

export const GET_REFERRED_BOOKINGS_SORTER = gql`
  query getReferredBookings($adminId: ID!) {
    getReferredBookings(adminId: $adminId) {
      id
      date
      status
      transactionId
      invoiceNo
      isReferred
      dateReferred
      dateClassified
      dateDenied
      user {
        id
        email
      }
      adminClassified {
        id
      }
      adminReferred {
        id
      }
      currentSorter {
        id
      }
      isLocked
    }
  }
`;

export const GET_DENIED_BOOKINGS = gql`
  query getDeniedBookings($adminId: ID!) {
    getDeniedBookings(adminId: $adminId) {
      id
      date
      status
      transactionId
      invoiceNo
      isReferred
      dateReferred
      dateClassified
      dateDenied
      user {
        id
        email
      }
      adminClassified {
        id
      }
      adminReferred {
        id
      }
      adminDenied {
        id
      }
      currentSorter {
        id
      }
      isLocked
    }
  }
`;

export const CHECK_CURRENT_DOCUMENT = gql`
  query checkIfHasCurrentDocument($adminId: ID!) {
    checkIfHasCurrentDocument(adminId: $adminId) {
      id
      currentDocument {
        id
      }
    }
  }
`;

export const CHECK_CURRENT_SORTER = gql`
  query checkIfHasCurrentSorter($userSearchTextId: ID!) {
    checkIfHasCurrentSorter(userSearchTextId: $userSearchTextId) {
      id
      currentSorter {
        id
      }
    }
  }
`;

export const GET_REASON_FOR_CANCELLATION_SORTER = gql`
  query {
    getReasonForCancelSorters {
      id
      value
      remarks
    }
  }
`;

export const GET_DOCUMENT_TEMPLATE_TOPLEVELS = gql`
  query getDocumentTemplateTopLevels {
    getDocumentTemplateTopLevels {
      id
      ord
      documentTypeLevel1
      documentTemplate {
        id
        ord
        documentTypeLevel2
        onlyForSorters
        enabled
        displayAmount
      }
    }
  }
`;

export const GET_DOCUMENT_TEMPLATES_TOPLEVELSINGLE = gql`
  query getDocumentTemplateLevel2s($topLevelId: ID!) {
    getDocumentTemplateLevel2s(topLevelId: $topLevelId) {
      id
      ord
      documentTypeLevel1
      documentTemplate {
        id
        ord
        documentTypeLevel2
        onlyForSorters
        enabled
        displayAmount
      }
    }
  }
`;

export const GET_DOCUMENT_TEMPLATES_FOR_SORTERS = gql`
  query getDocumentTemplatesForSorters {
    getDocumentTemplatesForSorters {
      id
      ord
      documentTypeLevel1
      documentTypeLevel2
      displayAmount
    }
  }
`;

export const GET_MEMBER_STATEMENTS = gql`
  query getStatements($status: [String!]!) {
    getStatements(status: $status) {
      id
      lastName
      firstName
      middleName
      email
      streetNumber
      barangay
      cityMunicipality
      province
      city
      zipCode
      bankName
      bankAccount
      statements {
        id
        amount
        status
        dateCreated
        dateApproved
        datePaid
        statementRefNo
        createdBy {
          id
          email
        }
        approvedBy {
          id
          email
        }
        bookings {
          id
          status
          transactionId
          invoiceNo
          payoutToMember {
            payableToMember
          }
          bookingStatus {
            id
            date
            status
            remarks
          }
          user {
            email
            firstName
            lastName
          }
        }
        period {
          dateFrom
          dateTo
        }
      }
    }
  }
`;

export const GET_STATEMENT_BOOKINGS = gql`
  query getStatementBookings($statementId: ID!) {
    getStatementBookings(statementId: $statementId) {
      transactionId
      invoiceNo
      bookingDate
      datePaid
      statusDate
      status
      documentTypeLevel1
      documentTypeLevel2
      documentAmount
      paymentMethod
      paymentDate
      paymentRefId
      last4
      brand
      paymentAmount
      commission
      netAmount
      fee
      foreignFee
      payableToMember
      receiptNo_PayableToMember
      netIncome
      taxDue
      revenue
      receiptNo_ToPrincipal
      memberId
      notaryEmail
      notaryContactNumber
      notaryFirstName
      notaryMiddleName
      notaryLastName
      n_StreetNumber
      n_Barangay
      n_CityMunicipality
      n_City
      n_Province
      customerEmail
      customerContactNumber
      firstName
      middleName
      lastName
      streetNumber
      barangay
      cityMunicipality
      city
      province
      id
    }
  }
`;

//
export const GET_UNMATCHED_PAYMENTS = gql`
  query getUnMatchedPayments {
    getUnMatchedPayments {
      id
      dateUploaded
      paymentID
      grossAmount
      fee
      foreignFee
      netAmount
      status
      paidAt
      availableAt
      sourceType
      cardBrand
      cardLast4Digits
      cardCountryOfOrigin
    }
  }
`;

export const GET_UNMATCHED_DEPOSITS = gql`
  query getUnMatchedDeposits {
    getUnMatchedDeposits {
      id
      dateUploaded
      paymentID
      grossAmount
      fee
      foreignFee
      netAmount
      status
      transactionDate
      paymentMethod
    }
  }
`;

export const GET_MATCHED_DEPOSITS = gql`
  query getMatchedDeposits {
    getMatchedDeposits {
      id
      dateUploaded
      depositDate
      status
      paymentID
      transactionDate
      amount
      fee
      foreignFee
      netAmount
      paymentMethod
    }
  }
`;

export const GET_PAYMENTS_NODEPOSITS = gql`
  query getPaymentsWoutDeposits {
    getPaymentsWoutDeposits {
      id
      date
      paymentID
      reconciled
      withDeposit
      amount
      fee
      foreignFee
      netAmount
      transactionId
      invoiceNo
      docLevel
      notary
      principal
      paymentMethod
    }
  }
`;

export const GET_PAYMENTS_EXPORT = gql`
  query getAllPayments($dateFrom: String, $dateTo: String) {
    getAllPayments(dateFrom: $dateFrom, dateTo: $dateTo) {
      paymongoPaymentRefID
      paymentDate
      reconciled
      dateReconciled
      paymongoPaymentFile
      paymentWithMatchingDeposit
      dateDepositWasMatched
      paymongoPayoutFile
      paymentStatus
      refundedAmount
      amountPaidByUser
      lessPaymongoFee
      lessPaymongoFxFee
      receivableFromPaymongo
      payableToNotary
      revenueMinusTax
      minusTax
      revenue
      bookingId
      datePaid
      bookingStatus
      dateNotarized
      docLevel
      statementRefNo
      bankName
      bankAccount
      statementPayoutStatus
      statementCreatedDate
      statementReleasedDate
      notary
      principal
      paymentMethod
    }
  }
`;

export const GET_DOCUMENTS_NEW = gql`
  query getDocumentsNew {
    getDocumentsNew {
      id
      ord
      description
      isLeaf
      children {
        id
        ord
        level
        description
        isLeaf
        docTemplate {
          id
          goUpload
          displayAmount
          description
        }
        children {
          id
          ord
          level
          description
          isLeaf
          docTemplate {
            id
            goUpload
            displayAmount
            description
          }
          children {
            id
            ord
            level
            description
            isLeaf
            docTemplate {
              id
              goUpload
              displayAmount
              description
            }
          }
        }
      }
    }
  }
`;

export const GET_MEMBER_DOCUMENT_SERVICES = gql`
  query getMemberDocumentServices($memberId: ID!) {
    getMemberDocumentServices(memberId: $memberId) {
      id
      rate
      fixedFee
      enabled
      fixedFeeOnly
      documentClass {
        id
        description
        docTemplates {
          id
          description
          version
        }
      }
      serviceTemplate {
        id
        displayAmount
        rate
        fixedFee
        enabled
        documentTemplate {
          id
          description
        }
        fixedFeeOnly
      }
    }
  }
`;

export const GET_DOCUMENT_CLASSES = gql`
  query getDocumentClasses {
    getDocumentClasses {
      id
      ord
      priceCalc
      description
    }
  }
`;

export const GET_DOCUMENT_TEMPLATES_V3 = gql`
  query getDocumentTemplatesV3 {
    getDocumentTemplatesV3 {
      id
      description
      enabled
      class {
        id
        ord
        description
        fixedFeeOnly
      }
    }
  }
`;

export const GET_DOC_SERVICES_MEMBER = gql`
  query getDocumentServicesForMember($memberId: ID!) {
    getDocumentServicesForMember(memberId: $memberId) {
      id
      rate
      fixedFee
      enabled
      documentClass {
        id
        description
        ord
        priceCalc
      }
    }
  }
`;

export const GET_DOC_SERVICE_TEMPLATE_MEMBER = gql`
  query getDocumentServiceTemplatesForMember($memberId: ID!) {
    getDocumentServiceTemplatesForMember(memberId: $memberId) {
      id
      rate
      fixedFee
      enabled
      displayAmount
      service {
        id
        fixedFeeOnly
        rate
        fixedFee
        enabled
        isPercentage
        documentClass {
          id
          description
          fixedFeeOnly
        }
      }
      documentTemplate {
        id
        description
        version
      }
    }
  }
`;

export const GET_DOCUMENT_TEMPLATES_FOR_DESCRIBE_AUTOCOMPLETE = gql`
  query getDocumentTemplatesForDescribeForAutoComplete {
    getDocumentTemplatesForDescribeForAutoComplete {
      dtid
      docType
      goUpload
      priceCalc
      displayAmount
    }
  }
`;

export const GET_NOTARY_FEES_RATES = gql`
  query getNotaryFeesRates($memberId: ID!) {
    getNotaryFeesRates(memberId: $memberId) {
      type
      id
      docServiceTemplates {
        type
        id
        docTemplateId
        docServiceTemplateId
        documentServiceId
        document
        fixedFeeOnly
        rate
        fixedFee
        enabled
      }
      docClass
      docClassId
      fixedFeeOnly
      rate
      fixedFee
      enabled
    }
  }
`;

export const GET_REFUND_ATTEMPTS = gql`
  query getRefundAttempts {
    getRefundAttempts {
      paymentMethod
      amount
      refundAmount
      refundType
      status
      refundRefId
      paymentRefId
      attemptDate
      transactionId
      docType
    }
  }
`;

export const GET_PAYMENTS_FOR_REFUND = gql`
  query getPaymentsForRefund {
    getPaymentsForRefund {
      id 
      date
      amount 
      netAmount
      fee 
      foreignFee
      commission
      refundedAmount
      status
      paymentMethod
      paymentRefId
      netIncome {
        id 
        revenue
      }
      payoutToMember {
        id
        date 
        payableToMember
      }
      booking {
        id 
        date
        transactionId
        invoiceNo
        status
        amount  
        docType
      }
      member {
        id 
        firstName
        lastName
        middleName
        mobileNumber
        email 
        bankName 
        bankAccount
      }
      user {
        id 
        firstName
        lastName
        middleName
        email
        mobileNumber
      }
      refund {
        id
      }
    }
  }
`

export const GET_REFUND_STUB = gql`
  query getRefundStubDetails($refundStubId: ID!){
    getRefundStubDetails(refundStubId: $refundStubId) {
      id
      date
      amount
      payment {
        id
      }
      refundId
      refundType
      refund {
        id
        status
      }
      status
      log
      booking {
        id
      }
      member {
        id
      }
      remarks
    }
  }
`

export const GET_PROCESSED_REFUNDS = gql`
  query getProcessedRefunds {
    getProcessedRefunds {
      id
      booking {
        id 
        status
        transactionId
        invoiceNo
        amount
        docType
      }
      date
      amount
      status # possible options: HOLD | REFUNDED | FAILED
      dateRefunded
      payment {
        id
        paymentMethod
        paymentRefId
      }
      refundId
      member {
        id 
        email
        firstName
        lastName
        middleName
        mobileNumber
      }
      refundType
      refundStub {
        id 
        date
        status
      }
    }
  }
`


export const GET_PROCESSED_REFUNDS_FOR_RECONCILIATION = gql`
  query getProcessedRefundsForReconciliation {
    getProcessedRefundsForReconciliation {
      id
      booking {
        id 
        status
        transactionId
        amount
      }
      date
      amount
      status # possible options: HOLD | REFUNDED | FAILED
      dateRefunded
      payment {
        id
        paymentMethod
        paymentRefId
      }
      refundId
      member {
        id 
        email
        firstName
        lastName
        middleName
        mobileNumber
      }
      refundType
      refundStub {
        id 
        date
        status
      }
    }
  }
`

export const GET_STATEMENTS_BY_STATUS = gql`
  query getStatementsByStatus(
    $statusArray: [String!]!
  ){
    getStatementsByStatus(
      statusArray: $statusArray
    ){
      id
      statementRefNo
      member {
        id 
        email
        lastName
        firstName
        middleName
        mobileNumber
        streetNumber
        barangay
        cityMunicipality
        province
        city
        zipCode
        bankName
        bankAccount
      }
      bookings {
        id
        status
        amount
        transactionId
        invoiceNo
        docType
        payoutToMember {
          id
          payableToMember
        }
        bookingStatus {
          id
          date
          status
          remarks
        }
        user {
          id
          email
          firstName
          lastName
          middleName
          mobileNumber
        }
        payment {
          id 
          status
          paymentRefId
          amount
          netAmount
          fee
          foreignFee
          commission
        }
      }
      amount
      status
      dateCreated
      dateApproved
      datePaid
      payout {
        id
        dateCreated
        createdBy {
          id 
          email
          lastName
          firstName
          middleName
          mobileNumber
        }
        datePaid
        paidBy {
          id 
          email
          lastName
          firstName
          middleName
          mobileNumber
        }
        bank
        bankAccount
        amount
        status
      }
      period {
        id
        dateFrom
        dateTo
      }
      bankName
      bankAccount
      datePublished
    }
  }
`

export default MEMBER_CHECK;
