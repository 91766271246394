import React, { createContext, useState, useContext } from 'react';

const DocumentStateContext = createContext();
const DocumentStateProvider = DocumentStateContext.Provider;

function DocumentProvider ({children}) {
  const [documentTemplate, setDocumentTemplate] = useState(null)
  const [documentTemplates, setDocumentTemplates] = useState(null)
  const [gridApi, setGridApi] = useState(null)
  const [gridApiScopes, setGridApiScopes] = useState(null)
  const [rowData, setRowData] = useState([])
  const [rowDataScopes, setRowDataScopes] = useState([])
  const [scope, setScope] = useState([])
  // const [openAddDocument, setOpenAddDocument] = useState(false)
  const [openEditDocument, setOpenEditDocument] = useState(false)
  const [openDocumentScopes, setOpenDocumentScopes] = useState(false)
  const [refreshDocumentTemplates, setRefreshDocumentTemplates] = useState(0)
  const [refreshDocumentTemplatesScopes, setRefreshDocumentTemplatesScopes] = useState(0)

  return (
    <DocumentStateProvider value={{
      gridApi, setGridApi,
      gridApiScopes, setGridApiScopes,
      documentTemplate, setDocumentTemplate,
      documentTemplates, setDocumentTemplates,
      scope, setScope,
      rowData, setRowData,
      rowDataScopes, setRowDataScopes,
      // openAddDocument, setOpenAddDocument,
      openEditDocument, setOpenEditDocument,
      openDocumentScopes, setOpenDocumentScopes,
      refreshDocumentTemplates, setRefreshDocumentTemplates,
      refreshDocumentTemplatesScopes, setRefreshDocumentTemplatesScopes
    }}>
      {children}
    </DocumentStateProvider>
  )
}

function useDocument() {
  const all = useContext(DocumentStateContext);
  return all;
}

export {DocumentProvider, useDocument}