import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Header from './components/Header/Header.js';
import HeaderLinks from './components/Header/HeaderLinks.js';
import Parallax from './components/Parallax/Parallax.js';
// import Footer from './components/Footer/Footer.js';

import GridContainer from './components/Grid/GridContainer.js';
import GridItem from './components/Grid/GridItem.js';

//import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle.js';
import signupPageStyle from 'assets/jss/material-kit-pro-react/views/signupPageStyle.js';

import {
  // withEmailVerification, 
  withAuthentication
} from './utils/Session';

import { withFirebase } from './utils/Firebase';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: null
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    // for firebase.firestore
    // this.unsubscribe = this.props.firebase.users().onSnapshot(snapshot => {
    //   let users = {};
    //   snapshot.forEach(doc => (users[doc.id] = doc.data()));

    //   this.setState({
    //     users
    //   });
    // });
  }

  componentWillUnmount() {
    // for firebase.firestore only
    //this.unsubscribe();
  }

  // src={require('assets/img/Systra-logo1.png')}
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Header
          // brand="Home"
          brand=''
          links={<HeaderLinks dropdownHoverColor="primary" />}
          fixed
          color="white"
          changeColorOnScroll={{
            height: 100,
            color: 'black'
          }}
        />

        <Parallax
          image={require('assets/img/bg10.jpg')}
          className={classes.parallax}
        >
          <div className={classes.container}>
            <GridContainer>
              <GridItem>
                <div className={classes.brand}>
                  <h1 style={{ fontSize: '70px', color: 'primary' }}>NotaryPublic360°</h1>
                  {/* <h1 style={{ fontSize: '70px', color: 'red' }}>sq</h1> */}
                  <h1 style={{ fontSize: '70px', color: 'gray' }}>Pay</h1>
                  {/* <h1 style={{ fontSize: '70px', color: 'gray' }}>ay</h1> */}
                  {/* <img
                    className="img-responsive standard-logo retina-logo"
                    width="352"
                    height="120"
                    src=""
                    alt="EsqPay"
                  /> */}
                  <h1>
                    <span className={classes.proBadge}>Notary Public 360°</span>
                  </h1>

                </div>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
      </React.Fragment>
    );
  }
}

//export default App;
export default withStyles(signupPageStyle)(
  withFirebase(withAuthentication(App))
);
