import React, { createContext, useState, useContext } from 'react';

const LocalStateContext = createContext();
const LocalStateProvider = LocalStateContext.Provider;

function FeaturesProvider({ children }) {

  const [features, setFeatures] = useState([])
  const [roles, setRoles] = useState([])

  const init = (roles_) => {
    console.log(`FeaturesProvider.init roles`)
    console.log(roles_)
    setRoles(roles_)
    resolve(roles_)

  }

  const resolve = (roles_) => {  // roles[]\
    console.log(`FeaturesProvider.resolve roles`)
    console.log(roles_)
    let features_ = []
    if (roles_ && roles_.length > 0) {
      roles_.map((r, i) => {
        console.log(i)
        console.log(r)
        let ff = r.roleRef.features  // features: {id, feature}[]
        if (ff && ff.length > 0) {
          ff.map((f, k) => {
            console.log(k)
            console.log(f)
            if (!features_.includes(f.feature))
              features_.push(f.feature)
            console.log(JSON.stringify(features_))
          })
        }
      })
    }
    setFeatures(features_)
    console.log(features_)
    localStorage.setItem("features", JSON.stringify(features_));
  }

  const addRole = (role) => {
    let roles_ = roles
    roles_.push(role)
    setRoles(roles_)
    resolve(roles_)
  }

  return (
    <LocalStateProvider
      value={{
        features,
        setFeatures,
        roles,
        init
      }}
    >
      {children}
    </LocalStateProvider>
  );
}

function useFeatures() {
  const all = useContext(LocalStateContext);
  return all;
}

export { FeaturesProvider, useFeatures };
