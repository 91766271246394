import { lazy } from 'react';


import FaceIcon from "@material-ui/icons/Face";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ExitToApp from "@material-ui/icons/ExitToApp";
import WorkIcon from '@material-ui/icons/Work';
import PersonAdd from '@material-ui/icons/PersonAdd';

const SignOutFirebase = lazy(() => import("screens/SignOutFirebase"))
const EsqPay = lazy(() => import("EsqPay"))
const Account = lazy(() => import("screens/Account"))

const Members = lazy(() => import("screens/members"))
const AdminApproval = lazy(() => import("screens/admin"))
const DocumentTypes = lazy(() => import("screens/DocumentTypes2"))
const Documents = lazy(() => import("screens/DocumentsSupervisor"))
const SignUpWeb = lazy(() => import('screens/SignUpWeb'))

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Home",
    icon: DashboardIcon,
    component: EsqPay,
    layout: "/app",
  },
  {
    path: "/admin-approval",
    name: "Admin approval",
    icon: FaceIcon,
    component: AdminApproval,
    layout: "/app",
  },
  {
    path: "/members",
    name: "OCC verification update",
    icon: FaceIcon,
    component: Members,
    layout: "/app",
  },
  {
    path: "/documents",
    name: "Documents",
    icon: WorkIcon,
    component: Documents,
    layout: "/app",
  },
  {
    path: "/document-types",
    name: "Document types",
    icon: WorkIcon,
    component: DocumentTypes,
    layout: "/app",
  },
  {
    path: "/account",
    name: "Account management",
    icon: FaceIcon,
    component: Account,
    layout: "/app",
  },
  {
    path: '/signupweb',
    name: 'Create notary account',
    icon: PersonAdd,
    // color: whiteColor,
    // bgColor: primaryColor[1],
    component: SignUpWeb,
    layout: '/app'
  },
  {
    path: "/signout",
    name: "Sign out",
    icon: ExitToApp,
    component: SignOutFirebase,
    layout: "/app",
  },
];
export default dashRoutes;
