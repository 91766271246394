import React, { Suspense, lazy, useEffect, useState } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import { createBrowserHistory } from "history";

import App from "../../App";
import "assets/scss/material-kit-pro-react.scss?v=1.8.0";
import { withAuthentication } from "utils/Session";

import { gql } from "@apollo/client";
import { useAuthUser } from "utils/AuthUserProvider";

import { split, from, HttpLink } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { WebSocketLink } from "@apollo/client/link/ws";

import { ApolloClient, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";

import { ADMIN_LOGIN } from "utils/Graphql/queries";

import supervisorRoutes from "pellucidroutes-supervisor.js";
import sorterRoutes from "pellucidroutes-sorter.js";
import pellucidRoutes from "pellucidroutes.js";
import defaultRoutes from "pellucidroutes-default.js";
import DocumentClassificationRedirect from "screens/DocumentClassificationRedirect/DocumentClassificationRedirect";
import { useFeatures } from "utils/FeaturesProvider";

// const httpLink = new HttpLink({
//   uri:
//     process.env.NODE_ENV === "development"
//       ? process.env.REACT_APP_endpoint
//       : process.env.REACT_APP_prodEndpoint,
//   request: (operation) => {
//     operation.setContext({
//       fetchOptions: {
//         credentials: "include",
//       },
//       headers: null,
//     });
//   },
// });

// Log any GraphQL errors or network error that occurred
// const errorLink = onError(({ graphQLErrors, networkError }) => {
//   if (graphQLErrors)
//     graphQLErrors.map(({ message, locations, path }) =>
//       console.log(
//         `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
//       )
//     );
//   if (networkError) console.log(`[Network error]: ${networkError}`);
// });

// const link = from([httpLink, errorLink]);
// const cache = new InMemoryCache();

const SignUpWeb = lazy(() => import("../SignUpWeb user"));
const SignInPage = lazy(() => import('screens/SignIn'))
const Password = lazy(() => import("screens/Account/Password"));
const PasswordForget = lazy(() => import("screens/PasswordForget"));
const PasswordForgot = lazy(() =>
  import("screens/PasswordForget/PasswordForgot")
);
const RegSuccessDialog = lazy(() => import("../SignUpWeb user/success"));
const SignOutFirebase = lazy(() => import("screens/SignOutFirebase"));
const Account = lazy(() => import("screens/Account"));
const AppDrawerLayout = lazy(() => import("../../layouts/AppDrawer.js"));
const SignInDrawerLayout = lazy(() => import("../../layouts/SignInDrawer.js"));
const DocumentsSupervisor = lazy(() => import("screens/DocumentsSupervisor"));
const DocumentsSorter = lazy(() => import("screens/DocumentsSorter"));

var hist = createBrowserHistory();

const Main = (props) => {
  const { client } = props;
  const { authUserContext, setAuthUserContext } = useAuthUser();
  const { features, setFeatures, init } = useFeatures()
  const [roles, setRoles] = useState([])
  const adminLogin = async (client, email) => {
    debugger;
    console.log(client, email);
    try {
      const data = await client.query({
        query: ADMIN_LOGIN,
        variables: {
          email,
        },
        fetchPolicy: "network-only",
      });
      return data;
    } catch (e) {
      throw e;
    }
  };

  useEffect(() => {
    let features = JSON.parse(localStorage.getItem("features"))
    if (features) {
      const sorterRoles = features?.filter(r => r === "Documents (Sorter)" || r === "Documents (Supervisor)")
      setRoles(sorterRoles)
    }
    let admin = JSON.parse(localStorage.getItem('admin'));
    setAuthUserContext({ admin: admin });
  }, [])  

  return (
    <Router history={hist} component={App}>
      {JSON.parse(localStorage.getItem("authUser")) === null ? (
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            {/* <Route
              exact
              path="/auth/signupweb"
              render={(props) => <SignUpWeb client={client} {...props} />}
            /> */}
            <Route
              path="/auth"
              render={(props) => (
                <SignInDrawerLayout client={client} {...props} />
              )}
            />
            <Route
              exact
              path="/auth/passwordforget"
              render={(props) => <PasswordForget client={client} {...props} />}
            />
            {/* <Route
              exact
              path="/auth/passwordforgot"
              render={(props) => <PasswordForgot client={client} {...props} />}
            /> */}
            <Route
              exact
              path="/auth/signin"
              render={(props) => <SignInPage client={client} {...props} />}
            />
            <Redirect from="/app/documents-redirect" to="/auth/signin" />
            <Redirect from="/" to="/auth/dashboard" />
          </Switch>
        </Suspense>
      ) : (
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route
              exact
              path="/app/documents-redirect"
              render={(props) => (
                <DocumentClassificationRedirect
                  client={client}
                  {...props}
                />
              )}
            />
            {roles?.includes("Documents (Supervisor)") ?
              <Route
                exact
                path="/app/documents-supervisor"
                render={(props) => (
                  <DocumentsSupervisor client={client} {...props} />
                )}
              />
              : null}
            {roles?.length === 1 && roles[0] === "Documents (Sorter)" ?
              <Route
                exact
                path="/app/documents-sorter"
                render={(props) => (
                  <DocumentsSorter client={client} {...props} />
                )}
              />
              : null}
            <Route
              path="/app"
              render={(props) => (
                <AppDrawerLayout
                  client={client}
                  routes_={pellucidRoutes}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/app/password"
              render={(props) => <Password client={client} {...props} />}
            />
            <Route
              exact
              path="/app/signout"
              render={(props) => <SignOutFirebase client={client} {...props} />}
            />
            {/* <Route
              exact
              path="/app/documents"
              render={(props) => (
                <DocumentsSupervisor client={client} {...props} />
              )}
            />
            <Route
              exact
              path="/app/account"
              render={(props) => <Account client={client} {...props} />}
            /> */}
            <Route
              exact
              path="/app/regsuccess"
              render={(props) => (
                <RegSuccessDialog client={client} {...props} />
              )}
            />
            <Redirect from="/" to="/app/dashboard" />
          </Switch>
        </Suspense>

      )}
    </Router>
  );
};

export default withAuthentication(Main);
